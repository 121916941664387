@use "@angular/material" as mat;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`

// @include mat.all-legacy-component-typographies();
// @include mat.legacy-core();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

@import "ngx-toastr/toastr";
@import "assets/sass/main-import.scss";

// @import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
// @import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

:root {
  --bodyColor: #ffffff;
  --primaryColor: #4a85c5;
  --headerColor: #1B5C8E;
  --actionCenterTextColor: #32548a;
  --warningColor: #f44336;
  --warningDarkColor: #e01e10;
  --whiteColor: #fff;
  --attendeColor: #00c850;
  --textColor: #3a3a3a;
  --borderColor: #cecece;
  --tableRowColor: #eff6ff;
  --webHeaderTextColor: #4a85c5;
}

$ia-weblink-primary: mat.define-palette(mat.$indigo-palette);
$ia-weblink-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ia-weblink-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ia-weblink-theme: mat.define-light-theme(
  (
    color: (
      primary: $ia-weblink-primary,
      accent: $ia-weblink-accent,
      warn: $ia-weblink-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

// @include mat.all-legacy-component-themes($ia-weblink-theme);
@include mat.all-component-themes($ia-weblink-theme);

@import "video.js/dist/video-js.css";
@import "videojs-record/dist/css/videojs.record.css";

/* You can add global styles to this file, and also import other style files */
* {
  outline: none;
}

html,
body {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  background: var(--bodyColor);
  font-size: 1.6rem;
}

.vjs-record.vjs-device-button.vjs-control.vjs-icon-av-perm {
  display: none;
}

.alert-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.7);
  display: none;
  z-index: 99999;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    background: #fff;
    border-radius: 2px;
    padding: 20px 20px;
    transform: translate3d(-50%, -50%, 0);
    p {
      margin: 0;
      text-align: center;
    }
  }
}
