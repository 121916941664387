@import "../base/colors";
@import "../helpers/mixins";

input::placeholder {
  font-size: 1.3rem;
}

input:-ms-input-placeholder {
  font-size: 1.3rem;
}

input::-ms-input-placeholder {
  font-size: 1.3rem;
}

hr {
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 20px;
  opacity: 1;
}

.ion-form-group {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  .label-text {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--textColor);
    text-transform: capitalize;
    display: block;
    width: 100%;
    &.inline {
      font-weight: 600;
    }
  }
  textarea {
    width: 100%;
    height: 120px;
    background: #fff;
    border: 1px solid var(--borderColor);
    @include border-radius(5px);
    font-size: 1.5rem;
    @include transitions(all, 0.3s, ease-in);
    color: var(--textColor);
    padding: 10px;
    &.ng-invalid.ng-touched {
      border: 1px solid var(--warningColor);
      @include transitions(all, 0.3s, ease-in);
    }
    &:disabled {
      background: #eee;
    }
  }
  input,
  select {
    width: 100%;
    height: 34px;
    background: #fff;
    border: 1px solid var(--borderColor);
    @include border-radius(5px);
    font-size: 1.5rem;
    @include transitions(all, 0.3s, ease-in);
    color: var(--textColor);
    padding: 0 10px;
    &[type="file"] {
      border: 0;
      padding: 0;
      background: none;
    }
    &.xs {
      width: 20%;
    }

    &.ng-invalid.ng-touched {
      border: 1px solid var(--warningColor);
      @include transitions(all, 0.3s, ease-in);
    }
    &:disabled {
      background: #eee;
    }
  }
}
