@import "../base/colors";
@import "../helpers/mixins";

.ion-theme-btn {
  @include border-radius(0);
  background: #fff;
  color: #fff;
  padding: 15px 30px;
  font-size: 1.5rem;
  font-weight: 600;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  text-align: center;
  text-transform: capitalize;
  @include transitions(all, 0.3s, ease-in-out);
  @include border-radius(8px);
  &.sm {
    height: 30px;
    padding: 0 15px;
    font-size: 1.2rem;
  }
  &.warning {
    background: var(--warningColor);
    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
    &:hover {
      background: var(--warningDarkColor);
      @include transitions(all, 0.3s, ease-in-out);
    }
  }
  &.primary {
    background: var(--primaryColor);
    &:disabled,
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &.active {
      opacity: 0.8;
    }
    &:hover {
      background: var(--primaryColor);
      @include transitions(all, 0.3s, ease-in-out);
    }
  }
  &.secondary {
    background: transparent;
    border: 1px solid var(--primaryColor);
    color: #242e42;
  }
}
